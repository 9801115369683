import { applyMiddleware, combineReducers, createStore } from 'redux';
import logger from 'redux-logger';
import userReducer from './reducers/user/user-reducer';
import searchReducer from './reducers/backoffice/search-reducer';
import playersReportReducer from './reducers/backoffice/players-report-reducer';
import playerReportReducer from './reducers/backoffice/player-report-reducer';
import gameHistoryReducer from './reducers/backoffice/game-history-reducer';
import gamesReportReducer from './reducers/backoffice/games-report-reducer';
import casinoPerformanceReducer from './reducers/backoffice/casino-performance-report-reducer';
import promotionsReducer from './reducers/backoffice/promotions-reducer';
import operatorsReducer from './reducers/configProvider/operators-reducer';
import gamesReducer from './reducers/configProvider/games-reducer';
import manageBrandReducer from './reducers/configProvider/manage-brand-reducer';
import addOperatorReducer from './reducers/configProvider/add-operator-reducer';
import errorReducer from './reducers/backoffice/error-reducer';
import casinoAdminReducer from './reducers/backoffice/casino-admin-reducer';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import generalConfigProviderReducer from './reducers/configProvider/general-reducer';
import currencyReducer from './reducers/configProvider/currency-reducer';
import {
    allCurrenciesByOperatorEpic,
    allCurrenciesEpic,
    changeDefaultPasswordEpic,
    changeUserNameEpic,
    changeUserPasswordEpic,
    countriesEpic,
    dateOfLastRefreshEpic,
    errorEpic,
    infoEpic,
    loginEpic,
    timezoneEpic,
    updatePreferencesEpic,
    allGamesByUserEpic,
    allGameConfigurationsEpic,
} from './epics/user-epics';
import {
    playersReportPageableEpic,
    playersReportGrandTotalEpic,
} from './epics/backoffice/players-report-epics';
import {
    blockUserEpic,
    changeNameByAdminEpic,
    changePasswordByAdminEpic,
    changeRoleUserEpic,
    createUserEpic,
    getUsersEpic,
    unblockUserEpic,
} from './epics/backoffice/casino-admin-epics';
import { casinoPerformanceEpic } from './epics/backoffice/casino-performance-report-epics';
import { gameHistoryEpic, getGameHistoryUrl } from './epics/backoffice/game-history-epics';
import { gamesReportEpic } from './epics/backoffice/games-report-epics';
import { searchEpic } from './epics/backoffice/search-epics';
import {
    playerCampaignsData,
    playerReportEpic,
    promotionDataEpic,
} from './epics/backoffice/player-report-epics';
import {
    allDefaultGameConfigurationsEpic,
    allGamesEpic,
    getDefaultConfiguredCurrenciesEpic,
} from './epics/configProvider/general-epics';
import {
    changeOperatorStatusEpic,
    getOperatorCurrenciesEpic,
    getOperatorGamesConfigurationsEpic,
    getOperatorGamesEpic,
    operatorsEpic,
    refreshServersEpic,
    updateOperatorConfigurationsEpic,
    updateOperatorCurrenciesEpic,
    updateOperatorGamesEpic,
} from './epics/configProvider/operators-epics';
import {
    addOperatorBrandsEpic,
    addOperatorConfigurationsEpic,
    addOperatorCurrenciesEpic,
    addOperatorGamesEpic,
    addOperatorNameEpic,
} from './epics/configProvider/add-operator-epics';
import {
    addNewGameDefaultConfigEpic,
    addNewGameEpic,
    getGameDefaultConfigurationsEpic,
    updateGameDefaultConfigurationsEpic,
} from './epics/configProvider/games-epics';
import {
    addNewBrandEpic,
    getBrandConfigsEpic,
    getBrandCurrenciesEpic,
    getBrandGamesEpic,
    getOperatorBrandsEpic,
    getOperatorCurrencyIdsEpic,
    getOperatorGameIdsEpic,
    updateBrandConfigsEpic,
    updateBrandCurrenciesEpic,
    updateBrandDataEpic,
    updateBrandStatusEpic,
    updateBrandGamesEpic,
    generateFreeRoundsCredentialsEpic,
    saveFreeRoundsCredentialsEpic,
} from './epics/configProvider/manage-brand-epics';
import {
    addDefaultCurrencyConfigEpic,
    getCurrencyConfigurationsEpic,
    getGameConfiguredCurrenciesEpic,
    getGameUnconfiguredCurrenciesEpic,
    updateCurrencyConfigurationsEpic,
} from './epics/configProvider/currencies-epics';
import {
    brokenRoundsEpic,
    resolveSessionEpic,
    retrySessionEpic,
    blockSessionEpic,
    statusHistoryEpic,
    brokenRoundsCountEpic,
    retrySessionsBatchEpic,
    unblockSessionsBatchEpic,
    putOnHoldBrokenRoundsBatchEpic,
    brokenRoundsJobStatus,
} from './epics/configProvider/broken-rounds-epic';
import brokenRoundsReducer from './reducers/configProvider/broken-rounds-reducer';
import { checksumReportEpic } from './epics/backoffice/checksum-report-epics';
import checksumReducer from './reducers/backoffice/checksum-reducer';
import { roundInfoEpicEpic } from './epics/configProvider/round-info-epic';
import roundInfoReducer from './reducers/configProvider/round-info-reducer';
import {
    finishRoundsEpics,
    unfinishedRoundsEpics,
} from './epics/configProvider/unfinished-rounds-epics';
import unfinishedRoundsReducer from './reducers/configProvider/unfinised-rounds-reducer';
import {
    createPromotionEpic,
    createPromotionWithCSVEpic,
    deletePromotionEpic,
    editPromotionEpic,
    editPromotionWithCSVEpic,
    getCampaignEpic,
    getPromotionsEpic,
    uploadPromotionCSVEpic,
    terminatePromotionEpic,
} from './epics/backoffice/promotions-epics';

//Add your epics here
const rootEpic: any = combineEpics(
    infoEpic,
    loginEpic,
    allGamesEpic,
    allCurrenciesEpic,
    timezoneEpic,
    brokenRoundsEpic,
    resolveSessionEpic,
    retrySessionEpic,
    blockSessionEpic,
    statusHistoryEpic,
    playersReportPageableEpic,
    playersReportGrandTotalEpic,
    playerReportEpic,
    promotionDataEpic,
    searchEpic,
    gamesReportEpic,
    gameHistoryEpic,
    getGameHistoryUrl,
    casinoPerformanceEpic,
    getUsersEpic,
    createUserEpic,
    blockUserEpic,
    unblockUserEpic,
    changeRoleUserEpic,
    changeNameByAdminEpic,
    changePasswordByAdminEpic,
    changeUserNameEpic,
    changeUserPasswordEpic,
    updatePreferencesEpic,
    allDefaultGameConfigurationsEpic,
    operatorsEpic,
    changeOperatorStatusEpic,
    addOperatorNameEpic,
    addOperatorGamesEpic,
    addOperatorCurrenciesEpic,
    addOperatorConfigurationsEpic,
    addOperatorBrandsEpic,
    refreshServersEpic,
    addNewGameEpic,
    addNewBrandEpic,
    updateBrandDataEpic,
    updateBrandStatusEpic,
    getOperatorBrandsEpic,
    getOperatorGameIdsEpic,
    getOperatorCurrencyIdsEpic,
    addNewGameDefaultConfigEpic,
    addDefaultCurrencyConfigEpic,
    getOperatorGamesConfigurationsEpic,
    updateOperatorConfigurationsEpic,
    getBrandGamesEpic,
    updateBrandGamesEpic,
    getBrandCurrenciesEpic,
    updateBrandCurrenciesEpic,
    getBrandConfigsEpic,
    updateBrandConfigsEpic,
    getOperatorGamesEpic,
    updateOperatorGamesEpic,
    getOperatorCurrenciesEpic,
    updateOperatorCurrenciesEpic,
    getDefaultConfiguredCurrenciesEpic,
    getGameDefaultConfigurationsEpic,
    updateGameDefaultConfigurationsEpic,
    getGameConfiguredCurrenciesEpic,
    getGameUnconfiguredCurrenciesEpic,
    getCurrencyConfigurationsEpic,
    updateCurrencyConfigurationsEpic,
    checksumReportEpic,
    roundInfoEpicEpic,
    unfinishedRoundsEpics,
    getPromotionsEpic,
    finishRoundsEpics,
    errorEpic,
    allCurrenciesByOperatorEpic,
    changeDefaultPasswordEpic,
    createPromotionEpic,
    editPromotionEpic,
    createPromotionWithCSVEpic,
    editPromotionWithCSVEpic,
    uploadPromotionCSVEpic,
    countriesEpic,
    getCampaignEpic,
    deletePromotionEpic,
    terminatePromotionEpic,
    generateFreeRoundsCredentialsEpic,
    saveFreeRoundsCredentialsEpic,
    playerCampaignsData,
    dateOfLastRefreshEpic,
    allGamesByUserEpic,
    allGameConfigurationsEpic,
    brokenRoundsCountEpic,
    retrySessionsBatchEpic,
    putOnHoldBrokenRoundsBatchEpic,
    unblockSessionsBatchEpic,
    brokenRoundsJobStatus,
);

//Add your reducers here
const rootReducer = combineReducers({
    generalConfigProviderReducer,
    brokenRoundsReducer,
    operatorsReducer,
    gamesReducer,
    manageBrandReducer,
    addOperatorReducer,
    currencyReducer,
    userReducer,
    playersReportReducer,
    playerReportReducer,
    gameHistoryReducer,
    gamesReportReducer,
    casinoPerformanceReducer,
    errorReducer,
    casinoAdminReducer,
    searchReducer,
    checksumReducer,
    roundInfoReducer,
    unfinishedRoundsReducer,
    promotionsReducer,
});
const epicMiddleware = createEpicMiddleware();
const middleware: any[] = [];

if (process.env.NODE_ENV === 'development') {
    middleware.push(epicMiddleware, logger);
} else {
    middleware.push(epicMiddleware);
}

export default function configureStore(initialState?: any) {
    const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));

    epicMiddleware.run(rootEpic);

    return store;
}

export type RootState = ReturnType<typeof rootReducer>;
